import {AppConfigService} from "@alfresco/adf-core";
import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router} from "@angular/router";
import {Observable} from "rxjs";

@Injectable({
    providedIn: "root"
})
export class AuthGuardMaintenance implements CanActivate {
    constructor(private router: Router, private appConfigService: AppConfigService) {}

    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        if (this.appConfigService.config.apps.maintenance.status) {
            this.router.navigate(["/status"]);
            return false;
        } else {
            return true;
        }
    }
}
