<div class="adf-form-document-widget {{ field.className }}">
    <ng-container>
        <div *ngIf="hasFile && fileId; else showMessage">
            <ng-container>
                <uofa-adf-content [id]="fileId" [showDocumentContent]="true"></uofa-adf-content>
            </ng-container>
        </div>
        <ng-template #showMessage>
            <p style="color: #275d38; font-size: large; margin-left: 25px">{{ message }}</p>
        </ng-template>
    </ng-container>
</div>
