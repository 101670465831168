<div class="{{ field.className }}" id="ccid-widget" [class.adf-invalid]="!field.isValid && isTouched()" [class.adf-left-label-input-container]="field.leftLabels">
    <div *ngIf="field.leftLabels">
        <label class="adf-label adf-left-label" [attr.for]="field.id">{{ field.name | translate }}<span class="adf-asterisk" *ngIf="isRequired()">*</span></label>
    </div>
    <div>
        <mat-form-field appearance="outline" [class.adf-left-label-input-datepicker]="field.leftLabels" [hideRequiredMarker]="true">
            <mat-label class="adf-label" *ngIf="!field.leftLabels" [attr.for]="field.id">{{ field.name | translate }}<span class="adf-asterisk" *ngIf="isRequired()">*</span></mat-label>
            <input
                matInput
                type="text"
                [attr.id]="field.id"
                [(ngModel)]="field.value"
                [required]="isRequired()"
                [disabled]="field.readOnly"
                (ngModelChange)="ccidOnChange(field)"
                (keyup)="onKey($event)"
                (focusout)="onFocusOut()"
                [placeholder]="field.placeholder"
                [matTooltip]="field.tooltip"
                (blur)="markAsTouched()"
                matTooltipPosition="above"
                matTooltipShowDelay="1000"
            />
        </mat-form-field>
        <p>{{ userName }}</p>
        <error-widget [error]="field.validationSummary"></error-widget>
        <error-widget *ngIf="isInvalidFieldRequired() && isTouched()" required="{{ 'FORM.FIELD.REQUIRED' | translate }}"></error-widget>
    </div>
</div>
