import moment from "moment";
import {AfterViewInit, Component, OnInit, ViewChild} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {UntypedFormGroup, UntypedFormControl} from "@angular/forms";
import {AppConfigService, BpmUserModel, ObjectDataTableAdapter} from "@alfresco/adf-core";
import {HistoricProcessInstanceQueryRepresentation, RestVariable, ProcessInstanceQueryRepresentation, TaskRepresentation, TaskQueryRepresentation, ProcessInstanceRepresentation} from "@alfresco/js-api";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {MatSelectChange} from "@angular/material/select";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TaskDetailsModel, TaskQueryRequestRepresentationModel} from "@alfresco/adf-process-services";
import {MatTabChangeEvent} from "@angular/material/tabs";
import {SelectionModel} from "@angular/cdk/collections";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {zip} from "rxjs";
import {AppStateService} from "app/services/state.service";

interface Column {
    key: string;
    title: string;
    asHyperlink: boolean;
    url: string;
    formate: string;
    displayAsDoc: boolean;
    filterable?: boolean;
}

@Component({
    selector: "app-tasks",
    templateUrl: "./tasks.component.html",
    styleUrls: ["./tasks.component.scss"]
})
export class TasksComponent implements OnInit, AfterViewInit {
    appName: string;
    appDefinitionId: number;
    apsHost: string;
    acsHost: string;

    dataSource: MatTableDataSource<any>;
    processDisplayedColumns: string[] = [];
    processSchema: Column[] = [];
    rowData = [];
    taskSchema: Column[] = [];
    taskDisplayedColumns: string[] = [];
    processTableFilters: Map<string, string> = new Map();

    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    isLoadingResults: boolean = true;
    isClaimClicked: boolean = false;

    @ViewChild(MatSort) sort: MatSort;
    sortedAllBy: ProcessInstanceQueryRepresentation.SortEnum = "created-desc";

    filterProcessAllBy: ProcessInstanceQueryRepresentation.StateEnum = "running";
    // "all" does not work
    filterTaskAllBy: TaskQueryRepresentation.StateEnum = "active";

    dataTable: ObjectDataTableAdapter;

    nameFilter: string = "";

    selectedTabIndex = 0;

    taskViews: any[] = [];

    showProgressFilter: boolean = false;

    range = new UntypedFormGroup({
        start: new UntypedFormControl(),
        end: new UntypedFormControl()
    });

    selection = new SelectionModel<any>(true, []);

    showTaskInfo: boolean;

    errorMessage: string = "";

    private enableApsUAEndpoint: boolean = false;

    private processMap: Map<string, {detail: any; process: ProcessInstanceRepresentation}> = new Map();
    private taskMap: Map<string, {detail: any; process: ProcessInstanceRepresentation}> = new Map();
    private processConfig: any;
    private userInfo: BpmUserModel;

    constructor(private route: ActivatedRoute, private router: Router, private snackBar: MatSnackBar, private appStateService: AppStateService, private appConfigService: AppConfigService) {
        this.initData();
    }

    ngOnInit() {
        this.route.params.subscribe(
            params => {
                const applicationId: number = Number(params["appId"]);
                if (applicationId && applicationId !== 0) {
                    this.appDefinitionId = applicationId;
                    this.processConfig = this.appConfigService.config.apps[applicationId];
                    if (this.processConfig) {
                        const userGroups = this.appConfigService.config.apps[applicationId].userGroups;
                        const superUserGroups = this.appConfigService.config.apps[applicationId].superUserGroups;
                        this.apsHost = this.appConfigService.config.bpmHost;
                        this.acsHost = this.appConfigService.config.ecmHost;
                        this.showTaskInfo = this.processConfig.isTaskBased;
                        this.taskViews = this.processConfig.tabs;
                        userGroups
                            ? this.appStateService.getUserInfo().subscribe(user => {
                                  let isManager = false;
                                  let isUser = false;
                                  this.userInfo = user;
                                  user.groups.forEach(group => {
                                      if (superUserGroups.indexOf(group.name) > -1 && group.status === "active") {
                                          isManager = true;
                                      }

                                      if (userGroups.indexOf(group.name) > -1 && group.status === "active") {
                                          isUser = true;
                                      }
                                  });
                                  if (isManager && this.processConfig.apsUAEndpointEnable) {
                                      //   this.taskViews = this.taskViews.filter(v => {
                                      //       return v.value !== "process";
                                      //   });
                                      this.enableApsUAEndpoint = this.processConfig.apsUAEndpointEnable;
                                  }
                                  if (!isUser) {
                                      this.router.navigate(["/status"]);
                                  }
                                  this.appName = this.appConfigService.config.apps[applicationId].name;
                                  this.processDisplayedColumns.push("select");
                                  this.processDisplayedColumns.push("index");
                                  this.processConfig.processSchema.forEach(config => {
                                      let colum: Column = {
                                          key: config.key,
                                          title: config.title,
                                          filterable: config.filterable,
                                          asHyperlink: config.asHyperlink ? true : false,
                                          url: this.getHostUrl(config.origin, config.url),
                                          formate: config.formate,
                                          displayAsDoc: config.displayAsDoc ? true : false
                                      };
                                      this.processSchema.push(colum);
                                      this.processDisplayedColumns.push(config.key);
                                  });

                                  this.taskDisplayedColumns.push("select");
                                  this.taskDisplayedColumns.push("index");
                                  this.processConfig.taskSchema.forEach(config => {
                                      let colum: Column = {
                                          key: config.key,
                                          title: config.title,
                                          asHyperlink: config.asHyperlink ? true : false,
                                          url: this.getHostUrl(config.origin, config.url),
                                          formate: config.formate,
                                          displayAsDoc: config.displayAsDoc ? true : false
                                      };
                                      this.taskSchema.push(colum);
                                      this.taskDisplayedColumns.push(config.key);
                                  });

                                  const pageSize = this.appConfigService.config.apps[this.appDefinitionId].pageSizeOption[0];
                                  this.loadData(pageSize, 0);
                                  this.isProcess() ? (this.showProgressFilter = true) : (this.showProgressFilter = false);
                              })
                            : this.router.navigate(["/status"]);
                    } else {
                        this.router.navigate(["/status"]);
                    }
                }
            },
            error => {
                console.log(error);
            }
        );
    }

    ngAfterViewInit() {
        if (this.dataSource && this.dataSource.data) {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
        }
    }

    dropProcessListDropped(event: CdkDragDrop<string[]>) {
        if (event) {
            moveItemInArray(this.processDisplayedColumns, event.previousIndex, event.currentIndex);
        }
    }

    dropTaskListDropped(event: CdkDragDrop<string[]>) {
        if (event) {
            moveItemInArray(this.taskDisplayedColumns, event.previousIndex, event.currentIndex);
        }
        // console.log(this.taskDisplayedColumns);
    }

    onPageChange(event: PageEvent) {
        this.loadData(event.pageSize, event.pageIndex);
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    onSortSelectionChange(event: MatSelectChange) {
        this.sortedAllBy = event.value;
        if (this.processConfig.showProcessInTask) {
            this.reloadData();
        }
    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            horizontalPosition: "center",
            verticalPosition: "bottom",
            panelClass: ["alert-snackbar"]
        });
    }

    onFilterProcessStatusChange(event: MatSelectChange) {
        this.filterProcessAllBy = event.value;
        if (event.value !== "running" && (this.range.value.start === null || this.range.value.end === null)) {
            this.range.patchValue({
                start: moment().startOf("month").toDate(),
                end: moment().endOf("month").toDate()
            });
        }
    }

    onTabChange(event: MatTabChangeEvent) {
        const sizeOptions = this.appConfigService.config.apps[this.appDefinitionId]?.pageSizeOption;
        this.selectedTabIndex = event.index;
        this.selection.clear();
        this.paginator.pageIndex = 0;
        this.isProcess() ? (this.showProgressFilter = true) : (this.showProgressFilter = false);
        this.loadData(sizeOptions[0], 0);
        this.paginator.pageSize = sizeOptions[0];
    }

    onFilterClick() {
        this.paginator.pageIndex = 0;
        this.loadData(this.paginator.pageSize, 0);
    }

    onClearFilterClick() {
        this.processTableFilters = new Map();
    }

    onFilterTaskStatusChange(event: MatSelectChange) {
        this.filterTaskAllBy = event.value;
        if (this.processConfig.showProcessInTask) {
            this.reloadData();
        }
    }

    onFilterByNameChange(value: string) {
        this.nameFilter = value;
    }

    onProcessFilterChange(e: any) {
        this.processTableFilters.set(e.target.id, e.target.value);
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    masterToggle() {
        if (this.isAllSelected()) {
            this.selection.clear();
            return;
        }
        this.selection.select(...this.dataSource.data);
    }

    onClaimAll() {
        if (this.showClaimButton() && this.selection.selected.length) {
            this.isClaimClicked = true;
            this.initData();
            let selectedTaskId = [];

            this.selection.selected.forEach(row => {
                if (row.id) {
                    selectedTaskId.push(row.id);
                } else if (row.lastTaskId) {
                    selectedTaskId.push(row.lastTaskId);
                }
            });
            let claimTasksRequest = [];
            selectedTaskId.forEach(taskId => {
                claimTasksRequest.push(this.appStateService.claimTask(taskId));
            });
            let claimTasks = zip(...claimTasksRequest);
            claimTasks.subscribe(
                tasksDetail => {
                    // tasksDetail is ""
                    this.loadData(this.paginator.pageSize, 0);
                },
                error => {
                    console.log(error);
                    this.isClaimClicked = false;
                },
                () => {
                    this.isClaimClicked = false;
                    this.selection.clear();
                }
            );
        }
    }

    onDownloadCSVFile() {
        let data = this.dataSource.data;
        if (this.selection.selected.length) {
            data = this.selection.selected;
        }
        const replacer = (key, value) => (value === null ? "" : value);
        let keys;
        let header = [];
        if (this.showProcessInfo()) {
            keys = this.processDisplayedColumns.filter(v => v !== "select" && v !== "index");
            keys.forEach(k => {
                header.push(this.processSchema.filter(c => c.key === k)[0]?.title);
            });
        } else {
            keys = this.taskDisplayedColumns.filter(v => v !== "select" && v !== "index");
            keys.forEach(k => {
                header.push(this.taskSchema.filter(c => c.key === k)[0]?.title);
            });
        }
        const csv = data.map(row => keys.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(","));
        csv.unshift(header.join(","));
        const csvArray = csv.join("\r\n");

        const a = document.createElement("a");
        const blob = new Blob([csvArray], {type: "text/csv"});
        const url = window.URL.createObjectURL(blob);

        a.href = url;
        let csvName = this.appName + " " + this.taskViews[this.selectedTabIndex].name + " " + new Date().toISOString().split("T")[0];
        a.download = this.userInfo?.fullname ? csvName + " " + this.userInfo.fullname : csvName;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }

    getPageSizeOptions(): number[] {
        let sizeOptions = this.appConfigService.config.apps[this.appDefinitionId]?.pageSizeOption;
        const total = this.paginator?.length;
        if (this.paginator && total) {
            let pageSizeOptions = [...sizeOptions];
            if (pageSizeOptions.indexOf(total) === -1) {
                this.isProcess() && this.isGetDataFromTask() ? null : pageSizeOptions.push(total);
            }
            return pageSizeOptions;
        } else {
            return sizeOptions;
        }
    }

    displayData(): boolean {
        return this.paginator.length > 0;
    }

    message() {
        if (this.errorMessage !== "") {
            return this.errorMessage;
        } else {
            return "No results found";
        }
    }

    showClaimButton() {
        return this.getAssignment() === "candidate";
    }

    sortName() {
        const assignment = this.getAssignment();
        if (assignment === "process") {
            return "workflow";
        }
        return "task";
    }

    isProcess() {
        return this.getAssignment() === "process";
    }

    showProcessInfo() {
        return this.isProcess() || this.taskSchema.length === 0 || (this.processConfig.showProcessInTask && this.filterTaskAllBy === "active");
    }

    settingToggleName(): string {
        if (this.isGetDataFromTask()) {
            return "Show Task Info";
        }
        return "Hide Task Info";
    }

    showDownloadLink(col: Column, element): boolean {
        if (col.displayAsDoc && element[col.key] && element.eSignStatusName === "workflow.completed") {
            return true;
        }
        return false;
    }

    clearDate() {
        this.range = new UntypedFormGroup({
            start: new UntypedFormControl(),
            end: new UntypedFormControl()
        });
    }

    clearTaskNameFilter() {
        this.nameFilter = "";
    }

    private hasProcessFilter(): boolean {
        let hasFilter = false;
        if (this.filterProcessAllBy === "running") {
            hasFilter = true;
        } else {
            if (this.range.value.start !== null && this.range.value.end !== null) {
                hasFilter = true;
            }
            if (this.processTableFilters.size) {
                hasFilter = true;
            }
        }
        return hasFilter;
    }

    private loadData(pageSize: number, pageIndex: number) {
        this.initData();
        this.isLoadingResults = true;
        const assignment = this.getAssignment();
        if (this.isProcess()) {
            if (this.isGetDataFromTask() && pageSize === this.paginator?.length) {
                this.errorMessage = "Due to the performance issue, user can not load all processes with associated task info.";
                this.isLoadingResults = false;
                this.dataSource = new MatTableDataSource([]);
            } else {
                if (this.hasProcessFilter()) {
                    const request = this.getProcessRequest(pageSize, pageIndex, this.sortedAllBy, this.filterProcessAllBy, this.range.value.start, this.range.value.end);
                    this.loadProcess(request);
                } else {
                    this.openSnackBar("For viewing process with status `Completed` and `All`, Please add some filters before click `Apply filters`", "Close");
                    this.isLoadingResults = false;
                }
            }
        } else {
            const taskRequest = this.createTaskRequest(assignment, pageSize, pageIndex, this.sortedAllBy, this.filterTaskAllBy, this.nameFilter);
            this.loadTask(taskRequest);
        }
    }

    private getAssignment() {
        return this.taskViews[this.selectedTabIndex].value;
    }

    private isGetDataFromTaskForm() {
        return this.processConfig?.isFormBased;
    }

    isGetDataFromTask() {
        return this.showTaskInfo && !this.enableCustomAPI();
    }

    private enableCustomAPI() {
        return this.enableApsUAEndpoint;
    }

    private loadProcess(request: HistoricProcessInstanceQueryRepresentation): void {
        this.rowData = [];
        this.processMap = new Map();
        const enableCustomAPI = this.enableCustomAPI();
        this.appStateService
            .getProcessInstances(request, enableCustomAPI)
            .then(processes => {
                // console.log(processes)
                this.paginator.length = processes.total;
                let taskFormPromises = [];
                let taskPromises = [];
                processes?.data?.forEach(process => {
                    const lastTaskId = this.getProcessVariableData(process.variables, "lastTaskId");
                    if (lastTaskId) {
                        this.processMap.set(lastTaskId, {detail: {}, process: process});
                        this.filterProcessAllBy !== "completed" && this.isGetDataFromTaskForm() ? taskFormPromises.push(this.appStateService.getLastTaskFormData(lastTaskId)) : "";
                        this.isGetDataFromTask() ? taskPromises.push(this.appStateService.getTask(lastTaskId)) : null;
                    } else {
                        this.processMap.set(process?.id, {detail: {}, process: process});
                        //console.log("In order to use process tab, we need to update lastTaskId as global variables for process (" + process?.id + ") when task created.");
                    }
                });

                if (this.isGetDataFromTask()) {
                    Promise.all(taskPromises)
                        .then(tasks => {
                            tasks.forEach(task => {
                                this.processMap.get(task.id).detail = task;
                            });
                        })
                        .then(() => {
                            if (this.filterProcessAllBy !== "completed" && this.isGetDataFromTaskForm()) {
                                Promise.all(taskFormPromises)
                                    .then(forms => {
                                        forms.forEach(form => {
                                            let process = this.processMap.get(form.taskId);
                                            process ? this.rowData.push(this.getRowData(process, form)) : "";
                                        });
                                    })
                                    .then(() => {
                                        this.isLoadingResults = false;
                                        this.dataSource = new MatTableDataSource(this.rowData);
                                        this.dataSource.sort = this.sort;
                                    })
                                    .catch(error => {
                                        this.errorMessage = error;
                                        console.log(error);
                                    });
                            } else {
                                this.processMap.forEach(process => {
                                    this.rowData.push(this.getRowData(process, null));
                                });
                                this.isLoadingResults = false;
                                this.dataSource = new MatTableDataSource(this.rowData);
                                this.dataSource.sort = this.sort;
                            }
                        })
                        .catch(error => {
                            this.errorMessage = error;
                            console.log(error);
                        });
                } else {
                    // var total = 0;
                    this.processMap.forEach(process => {
                        this.rowData.push(this.getRowData(process, null));
                        // total += process.process.variables.length;
                    });
                    this.isLoadingResults = false;
                    this.dataSource = new MatTableDataSource(this.rowData);
                    this.dataSource.sort = this.sort;
                    // console.log(total);
                }
            })
            .catch(error => {
                this.errorMessage = error;
                console.log(error);
            });
    }

    private loadTask(request: TaskQueryRequestRepresentationModel) {
        this.rowData = [];
        this.taskMap = new Map();
        let ids: {process: string; task: string}[] = [];
        let taskVariablePromises = [];
        let taskFormPromises = [];
        this.appStateService.getTasks(request).subscribe(
            taskList => {
                this.paginator.length = taskList.total;
                taskList.data.forEach(task => {
                    ids.push({process: task.processInstanceId, task: task.id});
                    let key = task.id;
                    this.taskMap.set(key, {detail: task, process: null});
                });
            },
            error => {
                this.errorMessage = error;
                console.log(error);
            },
            () => {
                if (this.processConfig.taskSchema?.length && !this.processConfig.showProcessInTask) {
                    this.taskMap.forEach(task => {
                        this.rowData.push(this.getTaskData(task));
                    });
                    // console.log(this.rowData)
                    this.isLoadingResults = false;
                    this.dataSource = new MatTableDataSource(this.rowData);
                    this.dataSource.sort = this.sort;
                } else {
                    if (this.filterTaskAllBy != "active") {
                        this.taskMap.forEach(task => {
                            this.rowData.push(this.getTaskData(task));
                        });
                        this.isLoadingResults = false;
                        this.dataSource = new MatTableDataSource(this.rowData);
                        this.dataSource.sort = this.sort;
                    } else {
                        ids.forEach(id => {
                            taskVariablePromises.push(this.appStateService.getProcessInstanceById(id));
                            this.filterTaskAllBy === "active" && this.isGetDataFromTaskForm() ? taskFormPromises.push(this.appStateService.getLastTaskFormData(id.task)) : "";
                        });

                        Promise.all(taskVariablePromises)
                            .then(tasksVariables => {
                                tasksVariables.forEach(instance => {
                                    // console.log(instance)
                                    const key = instance.ids.task;
                                    if (key) {
                                        this.taskMap.get(key).process = instance.process;
                                    } else {
                                        console.log(`Can not find process id of task id ${ids}`);
                                    }
                                });
                            })
                            .then(() => {
                                if (this.filterTaskAllBy === "active" && this.isGetDataFromTaskForm()) {
                                    Promise.all(taskFormPromises)
                                        .then(forms => {
                                            forms.forEach(form => {
                                                let task = this.taskMap.get(form.taskId);
                                                task ? this.rowData.push(this.getRowData(task, form)) : "";
                                            });
                                        })
                                        .then(() => {
                                            this.isLoadingResults = false;
                                            this.dataSource = new MatTableDataSource(this.rowData);
                                            this.dataSource.sort = this.sort;
                                        })
                                        .catch(error => {
                                            this.errorMessage = error;
                                            console.log(error);
                                        });
                                } else {
                                    this.taskMap.forEach(task => {
                                        this.rowData.push(this.getRowData(task, null));
                                    });
                                    this.isLoadingResults = false;
                                    this.dataSource = new MatTableDataSource(this.rowData);
                                    this.dataSource.sort = this.sort;
                                }
                            })
                            .catch(error => {
                                this.errorMessage = error;
                                console.log(error);
                            });
                    }
                }
            }
        );
    }

    private initData() {
        this.dataSource = new MatTableDataSource([]);
        this.errorMessage = "";
    }

    private reloadData() {
        const sizeOptions = this.appConfigService.config.apps[this.appDefinitionId]?.pageSizeOption;
        this.paginator.pageIndex = 0;
        this.selection.clear();
        this.loadData(sizeOptions[0], 0);
        this.paginator.pageSize = sizeOptions[0];
    }

    private isTaskCompleted(): boolean {
        return this.filterTaskAllBy === "completed";
    }

    private getProcessDataSchema(): string[] {
        let schema: string[] = [];
        this.processConfig.processSchema.forEach(config => {
            if (!config.processField) {
                schema.push(config.key);
            }
        });
        return schema;
    }

    private getProcessInstanceVariablesLimit(): number {
        let processInstanceVariablesLimit = 20000;
        if (this.processConfig.processInstanceVariablesLimit) {
            return this.processConfig.processInstanceVariablesLimit;
        }
        return processInstanceVariablesLimit;
    }

    private getProcessRequest(size: number, pageIndex: number, sort: ProcessInstanceQueryRepresentation.SortEnum, finished: ProcessInstanceQueryRepresentation.StateEnum, start: Date, end: Date): HistoricProcessInstanceQueryRepresentation {
        let status: boolean | "" = "";
        if (finished === "running") status = false;
        if (finished === "completed") status = true;
        // ADF bug !!! The `start` is page number

        // APS ADF inconsistent bug !!! variableOperation do not exist in APS
        // EQUALS("equals"),
        // NOT_EQUALS("notEquals"),
        // EQUALS_IGNORE_CASE("equalsIgnoreCase"),
        // NOT_EQUALS_IGNORE_CASE("notEqualsIgnoreCase"),
        // LIKE("like"), The string can include the wildcard character '%' to express like-strategy, can only apply to string type
        // LIKE_IGNORE_CASE("likeIgnoreCase"),
        // GREATER_THAN("greaterThan"),
        // GREATER_THAN_OR_EQUALS("greaterThanOrEquals"),
        // LESS_THAN("lessThan"),
        // LESS_THAN_OR_EQUALS("lessThanOrEquals");
        // https://www.programcreek.com/java-api-examples/?code=dingziyang%2Factiviti6-boot2%2Factiviti6-boot2-master%2Fmodules%2Factiviti-rest%2Fsrc%2Fmain%2Fjava%2Forg%2Factiviti%2Frest%2Fservice%2Fapi%2Fhistory%2FHistoricTaskInstanceBaseResource.java#
        // https://www.activiti.org/javadocs/6.latest/org/activiti/engine/task/TaskInfoQuery.html#processVariableValueLike(java.lang.String,%20java.lang.String)
        // let variables = [{
        //     name: "currentExternalId",
        //     operation: "notEquals",
        //     type: "string",
        //     value: "edrmsat2"
        // }];
        let request;
        const enableCustomAPI = this.enableCustomAPI();
        if (enableCustomAPI) {
            const processSchema = this.getProcessDataSchema();
            request = new HistoricProcessInstanceQueryRepresentation({
                processDefinitionKey: this.processConfig.processDefinitionKey,
                size: size,
                start: pageIndex,
                sort: sort,
                finished: status,
                includeTaskAssignee: true,
                processInstanceVariablesLimit: this.getProcessInstanceVariablesLimit(),
                variables: [],
                dataSchema: processSchema,
                includeProcessVariables: true,
                userGroup: this.processConfig.superUserGroups
            });
        } else {
            request = new HistoricProcessInstanceQueryRepresentation({
                processDefinitionKey: this.processConfig.processDefinitionKey,
                includeProcessVariables: true,
                size: size,
                start: pageIndex,
                sort: sort,
                finished: status
            });
        }

        if (this.processTableFilters.size) {
            let variables = [];
            let operation = "like";
            if (enableCustomAPI) {
                operation = operation.toUpperCase();
            }
            this.processTableFilters.forEach((value, key) => {
                if (value) {
                    variables.push({
                        name: key,
                        operation: operation,
                        type: "string",
                        value: "%" + value + "%"
                    });
                }
            });
            request.variables = variables;
        }

        if (start && end) {
            request.startedBefore = end;
            request.startedAfter = start;
        }
        return request;
    }

    private createTaskRequest(assignment: string, size: number, pageIndex: number, sort: ProcessInstanceQueryRepresentation.SortEnum, state: TaskQueryRepresentation.StateEnum, nameFilter: string) {
        let request = new TaskQueryRequestRepresentationModel({
            appDefinitionId: this.appDefinitionId,
            assignment: assignment,
            includeProcessInstance: true,
            size: size,
            page: pageIndex,
            sort: sort,
            state: state
        });
        if (nameFilter) {
            request.text = nameFilter;
        }
        return request;
    }

    private getTaskVariableData(variables: RestVariable[], key: string) {
        for (let index = 0; index < variables.length; index++) {
            const element: any = variables[index];
            if (element?.id === key) {
                return element.value;
            }
        }
    }

    // this could be optimized by converting to map
    private getProcessVariableData(variables: RestVariable[], key: string) {
        for (let index = 0; index < variables.length; index++) {
            const element: any = variables[index];
            if (element?.name === key) {
                return element.value;
            }
        }
    }

    private getTaskData(dataObj: {detail: TaskDetailsModel | TaskRepresentation}) {
        let row = {};
        this.processConfig.taskSchema.forEach(columnConfig => {
            const key = columnConfig.key;
            let keyValue = "";
            const columnKey = columnConfig.key;
            const value = dataObj.detail[columnKey];
            if (columnConfig.key === "assignee" && value) {
                const firstName = value.firstName ? value.firstName : "";
                const lastName = value.lastName ? value.lastName : "";
                const externalId = value.externalId ? value.externalId : "";
                keyValue = `${firstName} ${lastName} (${externalId})`;
            } else {
                keyValue = value;
            }
            if (columnConfig.type === "date" && columnConfig.formate && keyValue) {
                keyValue = moment(keyValue).format(columnConfig.formate);
            }

            if (columnConfig.type === "ms" && keyValue) {
                keyValue = this.msToDHM(Number(keyValue));
            }
            row[key] = keyValue;
        });
        return row;
    }

    private getRowData(dataObj: {detail: TaskDetailsModel | TaskRepresentation; process: ProcessInstanceRepresentation}, form: any) {
        let row = {};
        const enableCustomAPI = this.enableCustomAPI();
        let processVariablesMap;
        if (enableCustomAPI) {
            processVariablesMap = dataObj?.process?.variables as any;
        } else {
            processVariablesMap = dataObj?.process?.variables.reduce((map, obj) => {
                map[obj.name] = obj.value;
                return map;
            }, {});
        }

        this.processConfig.processSchema.forEach(columnConfig => {
            const key = columnConfig.key;
            let keyValue = "";
            if (columnConfig.form && form) {
                // get value from form
                const rowLayout = form.fields[columnConfig.form.rowIndex];
                rowLayout.fields[columnConfig.form.subKey].forEach(element => {
                    // get value according backend condition
                    if (element.visibilityCondition?.rightValue) {
                        if (element.visibilityCondition?.rightValue === row[columnConfig.form.visibilityConditionId]) {
                            keyValue = element.value;
                        }
                    } else {
                        keyValue = element.value;
                    }
                });
            } else if (columnConfig.taskField) {
                // get value from task
                const columnKey = columnConfig.key;
                const value = dataObj.detail[columnKey];
                if (columnConfig.key === "assignee" && value) {
                    const firstName = value.firstName ? value.firstName : "";
                    const lastName = value.lastName ? value.lastName : "";
                    const externalId = value.externalId ? value.externalId : "";
                    keyValue = `${firstName} ${lastName} (${externalId})`;
                } else {
                    keyValue = value;
                }
            } else {
                const columnKey = columnConfig.key;
                if (columnConfig.processField) {
                    // get value from process
                    keyValue = dataObj.process[columnKey];
                } else {
                    // get value from process variables
                    if (columnKey === "eSignObjectId") {
                        keyValue = processVariablesMap[columnKey]?.split(";")[0];
                    } else {
                        keyValue = processVariablesMap[columnKey];
                    }
                }
            }
            if (columnConfig.type === "date" && columnConfig.formate && keyValue) {
                keyValue = moment(keyValue).format(columnConfig.formate);
            }

            if (columnConfig.type === "ms" && keyValue) {
                keyValue = this.msToDHM(Number(keyValue));
            }

            row[key] = keyValue;
        });
        return row;
    }

    private msToDHM(v: number) {
        var days = (v / 8.64e7) | 0;
        var hrs = ((v % 8.64e7) / 3.6e6) | 0;
        var mins = Math.round((v % 3.6e6) / 6e4);

        return days + ":" + z(hrs) + ":" + z(mins);

        function z(n) {
            return (n < 10 ? "0" : "") + n;
        }
    }

    private getHostUrl(host: string, url: string) {
        switch (host) {
            case "ecmHost":
                return this.acsHost + url;
            case "localHost":
                return window.location.origin + "/apps/" + this.appDefinitionId + "/task/";
            default:
                return this.apsHost + url;
        }
    }
}
