/* tslint:disable */

import {Component, NgModule, OnInit} from "@angular/core";
import {WidgetComponent} from "@alfresco/adf-core";
import {MaterialModule} from "@alfresco/adf-process-services";
import {FormsModule} from "@angular/forms";
import {CCIDWidgetComponent} from "app/widgets/ccid/ccidLookUp.component";
import {CoreModule} from "@alfresco/adf-core";
import {DisplayHTMLWidgetComponent} from "./widgets/display-html/display-html.component";
import {UofaDocumentWidgetComponent} from "./widgets/document/uofa-document.component";
import {UofaContentWidgetComponent} from "./widgets/document/uofa-content.component";
import {DisplayRichTextWidgetComponent} from "./widgets/display-rich-text/display-rich-text.widget";
import {SiteListWidgetComponent} from "./widgets/sitelist/sitelist.component";
import {SitesDropdownModule} from "@alfresco/adf-content-services";
import {SiteGroupListWidgetComponent} from "./widgets/sitelist/sitegrouplist/sitegrouplist.component";
import {CCIDListWidgetComponent} from "./widgets/ccidlist/ccidList.component";

@Component({
    selector: "custom-editor",
    template: ` <div style="color: red">Look, I'm a custom editor!</div> `
})
export class CustomEditorComponent extends WidgetComponent {
    constructor() {
        super();
    }
}

@NgModule({
    declarations: [CCIDWidgetComponent, DisplayHTMLWidgetComponent, SiteListWidgetComponent, SiteGroupListWidgetComponent, CCIDListWidgetComponent, UofaDocumentWidgetComponent, UofaContentWidgetComponent, DisplayRichTextWidgetComponent],
    exports: [CCIDWidgetComponent, DisplayHTMLWidgetComponent, SiteListWidgetComponent, SiteGroupListWidgetComponent, CCIDListWidgetComponent, UofaDocumentWidgetComponent, UofaContentWidgetComponent, DisplayRichTextWidgetComponent],
    entryComponents: [CCIDWidgetComponent, DisplayHTMLWidgetComponent, SiteListWidgetComponent, SiteGroupListWidgetComponent, CCIDListWidgetComponent, UofaDocumentWidgetComponent, UofaContentWidgetComponent, DisplayRichTextWidgetComponent],
    imports: [MaterialModule, FormsModule, CoreModule, SitesDropdownModule]
})
export class StencilsModule {}
