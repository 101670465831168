import {AppConfigService, AppConfigValues} from "@alfresco/adf-core";
import {Component} from "@angular/core";
import {AppStateService} from "app/services/state.service";

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"]
})
export class LoginComponent {
    logoImageUrl = "assets/images/Primary_Logo_Complete_Package/Green/Digital/UA_Logo_Green_RGB.png";
    backgroundImageUrl = "assets/images/background_wavy-color-lines.svg";
    successRoute = "/";
    showFooterMessage = false;

    constructor(public appStateService: AppStateService, private appConfigService: AppConfigService) {
        this.showFooterMessage = this.appConfigService.get(AppConfigValues.AUTHTYPE) === "OAUTH";
    }
}
