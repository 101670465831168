import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {ProcessModule} from "@alfresco/adf-process-services";
import {CoreModule, TRANSLATION_PROVIDER, TranslateLoaderService, CoreAutomationService} from "@alfresco/adf-core";
import {TranslateModule, TranslateLoader} from "@ngx-translate/core";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatSortModule} from "@angular/material/sort";
import {MatTableModule} from "@angular/material/table";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatTabsModule} from "@angular/material/tabs";
import {MatInputModule} from "@angular/material/input";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatStepperModule} from "@angular/material/stepper";

// Services
import {AppStateService} from "./services/state.service";

// Custom stencils
import {StencilsModule} from "./stencils.module";

// App components
import {AppComponent} from "./app.component";
import {LoginComponent} from "./login/login.component";
import {AppsComponent} from "./apps/apps.component";
import {TasksComponent} from "./tasks/tasks.component";
import {UofATaskFormComponent} from "./task-form/task-form.component";
import {appRoutes} from "./app.routes";
import {AppLayoutComponent} from "./app-layout/app-layout.component";
import {PageStatusComponent} from "./page-status/page-status.component";
import {UofAFormComponent} from "./task-form/form/form.component";
import {UofaFormBaseComponent} from "./task-form/form/uofa-form-base.component";
import {MatSelectModule} from "@angular/material/select";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {UofaStartProcessComponent} from "./start-process/start-process.component";
import {UofaProcessCommentsComponent} from "./process-comment/process-comments.component";
import {FacultyListWidgetComponent} from "./widgets/facultylist/facultylist.component";

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        DragDropModule,
        HttpClientModule,
        MatPaginatorModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatSnackBarModule,
        MatStepperModule,
        RouterModule.forRoot(
            appRoutes // ,
            // { enableTracing: true } // <-- debugging purposes only
        ),
        // ADF modules
        CoreModule.forRoot(),
        ProcessModule.forRoot(),
        TranslateModule.forRoot({
            loader: {provide: TranslateLoader, useClass: TranslateLoaderService}
        }),
        StencilsModule
    ],
    declarations: [
        AppComponent,
        AppsComponent,
        LoginComponent,
        TasksComponent,
        AppLayoutComponent,
        PageStatusComponent,
        UofATaskFormComponent,
        UofAFormComponent,
        UofaFormBaseComponent,
        UofaStartProcessComponent,
        UofaProcessCommentsComponent,
        FacultyListWidgetComponent
    ],
    providers: [AppStateService],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(automationService: CoreAutomationService) {
        automationService.setup();
    }
}
