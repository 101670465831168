import {FormFieldModel, FormService, WidgetComponent} from "@alfresco/adf-core";
import {Component, OnInit} from "@angular/core";
import {FormFieldValidator} from "@alfresco/adf-core";
import {SiteEntry} from "@alfresco/js-api";

@Component({
    selector: "sitelist-widget",
    templateUrl: "./sitelist.component.html",
    styleUrls: ["./sitelist.component.scss"]
})
export class SiteListWidgetComponent extends WidgetComponent implements OnInit {
    siteName: string = "";
    selectedSite: string;
    message: string;
    fieldValidator = new SiteFieldValidator(this.siteName);

    constructor(public formService: FormService) {
        super(formService);
    }

    ngOnInit(): void {
        this.field.form.fieldValidators.push(this.fieldValidator);
    }

    getSiteContent(eventStr: SiteEntry) {
        this.siteName = eventStr.entry.id;
        this.field.value = this.siteName;
    }

    addGroup(selectedGroup: string) {
        this.field.value = this.siteName + ":" + selectedGroup;
    }
}

class SiteFieldValidator implements FormFieldValidator {
    constructor(public siteName: string) {}

    isSupported(field: FormFieldModel): boolean {
        return field && field.type === "sitelist";
    }

    validate(field: FormFieldModel): boolean {
        var lrc = true;
        if (this.isSupported(field)) {
            if (this.siteName === null) {
                lrc = false;
            }

            if (field?.value === null) {
                lrc = false;
            }
        }

        return lrc;
    }
}
