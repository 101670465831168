import {Component, ViewEncapsulation} from "@angular/core";
import {ProcessCommentsComponent} from "@alfresco/adf-process-services";

@Component({
    selector: "uofa-adf-process-comments",
    templateUrl: "./process-comments.component.html",
    styleUrls: ["process-comments.component.scss"],
    encapsulation: ViewEncapsulation.Emulated,
    host: {class: "uofa-adf-process-comments"}
})
export class UofaProcessCommentsComponent extends ProcessCommentsComponent {
    add() {
        super.add();
    }
}
