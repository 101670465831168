import {Component, EventEmitter, Input, Output, ViewEncapsulation, SimpleChanges, OnInit, OnDestroy, OnChanges} from "@angular/core";
import {
    EcmModelService,
    NodeService,
    WidgetVisibilityService,
    FormService,
    FormBaseComponent,
    FormOutcomeModel,
    FormEvent,
    FormErrorEvent,
    FormFieldModel,
    FormModel,
    FormOutcomeEvent,
    FormValues,
    ContentLinkModel,
    AppConfigService,
    GroupModel
} from "@alfresco/adf-core";
import {Observable, of, Subject} from "rxjs";
import {switchMap, takeUntil} from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: "uofa-form-base",
    templateUrl: "./uofa-form-base.component.html",
    encapsulation: ViewEncapsulation.None
})

// https://github.com/Alfresco/alfresco-ng2-components/blob/5.0.0/lib/core/src/lib/form/components/form-base.component.ts
export class UofaFormBaseComponent extends FormBaseComponent implements OnInit, OnDestroy, OnChanges {
    /** Underlying form model instance. */
    @Input()
    form: FormModel;

    /** Task id to fetch corresponding form and values. */
    @Input()
    taskId: string;

    /** Content Services node ID for the form metadata. */
    @Input()
    nodeId: string;

    /** The id of the form definition to load and display with custom values. */
    @Input()
    formId: number;

    /** Name of the form definition to load and display with custom values. */
    @Input()
    formName: string;

    /** Toggle saving of form metadata. */
    @Input()
    saveMetadata: boolean = false;

    /** Custom form values map to be used with the rendered form. */
    @Input()
    data: FormValues;

    /** The form will set a prefixed space for invisible fields. */
    @Input()
    enableFixedSpacedForm: boolean = true;

    /** Emitted when the form is submitted with the `Save` or custom outcomes. */
    @Output()
    formSaved: EventEmitter<{form: FormModel; type: string}> = new EventEmitter<{form: FormModel; type: string}>();

    /** Emitted when the form is submitted with the `Complete` outcome. */
    @Output()
    formCompleted: EventEmitter<{form: FormModel; type: string}> = new EventEmitter<{form: FormModel; type: string}>();

    /** Emitted when form content is clicked. */
    @Output()
    formContentClicked: EventEmitter<ContentLinkModel> = new EventEmitter<ContentLinkModel>();

    /** Emitted when the form is loaded or reloaded. */
    @Output()
    formLoaded: EventEmitter<FormModel> = new EventEmitter<FormModel>();

    /** Emitted when form values are refreshed due to a data property change. */
    @Output()
    formDataRefreshed: EventEmitter<FormModel> = new EventEmitter<FormModel>();

    debugMode: boolean = false;

    protected onDestroy$ = new Subject<boolean>();

    appId: string = "";

    formOutcomeBypass: string = "";

    formOutcomeBypassGroup: GroupModel = {};

    constructor(protected formService: FormService, protected visibilityService: WidgetVisibilityService, protected ecmModelService: EcmModelService, protected nodeService: NodeService, private appConfigService: AppConfigService, private route: ActivatedRoute) {
        super();
    }

    ngOnInit() {
        this.formService.formContentClicked.pipe(takeUntil(this.onDestroy$)).subscribe(content => this.formContentClicked.emit(content));

        this.formService.validateForm.pipe(takeUntil(this.onDestroy$)).subscribe(validateFormEvent => {
            if (validateFormEvent.errorsField.length > 0) {
                this.formError.next(validateFormEvent.errorsField);
            }
        });

        //get the value that bypasses from checks
        this.route.params.subscribe(
            params => {
                this.appId = params["appId"];

                if (this.appId) {
                    this.formOutcomeBypass = this.appConfigService.config.apps[this.appId]?.formOutcomeBypassKey;
                    this.formOutcomeBypassGroup.externalId = this.appConfigService.config.apps[this.appId]?.defaultGroupsOnBypass[0];
                    this.formOutcomeBypassGroup.groups = this.appConfigService.config.apps[this.appId]?.defaultGroupsOnBypass[1];
                    this.formOutcomeBypassGroup.id = this.appConfigService.config.apps[this.appId]?.defaultGroupsOnBypass[2];
                    this.formOutcomeBypassGroup.name = this.appConfigService.config.apps[this.appId]?.defaultGroupsOnBypass[3];
                    this.formOutcomeBypassGroup.status = this.appConfigService.config.apps[this.appId]?.defaultGroupsOnBypass[4];

                }
            },
            error => {
                console.log(error);
            }
        );
    }

    ngOnDestroy() {
        this.onDestroy$.next(true);
        this.onDestroy$.complete();
    }

    ngOnChanges(changes: SimpleChanges) {
        const taskId = changes["taskId"];
        if (taskId && taskId.currentValue) {
            this.getFormByTaskId(taskId.currentValue);
            return;
        }

        const formId = changes["formId"];
        if (formId && formId.currentValue) {
            this.getFormDefinitionByFormId(formId.currentValue);
            return;
        }

        const formName = changes["formName"];
        if (formName && formName.currentValue) {
            this.getFormDefinitionByFormName(formName.currentValue);
            return;
        }

        const nodeId = changes["nodeId"];
        if (nodeId && nodeId.currentValue) {
            this.loadFormForEcmNode(nodeId.currentValue);
            return;
        }

        const data = changes["data"];
        if (data && data.currentValue) {
            this.refreshFormData();
            return;
        }
    }

    /**
     * Invoked when user clicks form refresh button.
     */
    onRefreshClicked() {
        this.loadForm();
    }

    loadForm() {
        if (this.taskId) {
            this.getFormByTaskId(this.taskId);
            return;
        }

        if (this.formId) {
            this.getFormDefinitionByFormId(this.formId);
            return;
        }

        if (this.formName) {
            this.getFormDefinitionByFormName(this.formName);
            return;
        }
    }

    findProcessVariablesByTaskId(taskId: string): Observable<any> {
        return this.formService.getTask(taskId).pipe(
            switchMap((task: any) => {
                if (this.isAProcessTask(task)) {
                    return this.visibilityService.getTaskProcessVariable(taskId);
                } else {
                    return of({});
                }
            })
        );
    }

    isAProcessTask(taskRepresentation) {
        return taskRepresentation.processDefinitionId && taskRepresentation.processDefinitionDeploymentId !== "null";
    }

    getFormByTaskId(taskId: string): Promise<FormModel> {
        return new Promise<FormModel>(resolve => {
            this.findProcessVariablesByTaskId(taskId).subscribe(taskProcessVariables => {
                this.formService.getTaskForm(taskId).subscribe(
                    form => {
                        const parsedForm = this.parseForm(form);
                        this.visibilityService.refreshVisibility(parsedForm, taskProcessVariables);
                        parsedForm.validateForm();
                        this.form = parsedForm;
                        this.onFormLoaded(this.form);
                        resolve(this.form);
                    },
                    error => {
                        this.handleError(error);
                        resolve(null);
                    }
                );
            });
        });
    }

    getFormDefinitionByFormId(formId: number) {
        this.formService.getFormDefinitionById(formId).subscribe(
            form => {
                this.formName = form.name;
                this.form = this.parseForm(form);
                this.visibilityService.refreshVisibility(this.form);
                this.form.validateForm();
                this.onFormLoaded(this.form);
            },
            error => {
                this.handleError(error);
            }
        );
    }

    getFormDefinitionByFormName(formName: string) {
        this.formService.getFormDefinitionByName(formName).subscribe(
            id => {
                this.formService.getFormDefinitionById(id).subscribe(
                    form => {
                        this.form = this.parseForm(form);
                        this.visibilityService.refreshVisibility(this.form);
                        this.form.validateForm();
                        this.onFormLoaded(this.form);
                    },
                    error => {
                        this.handleError(error);
                    }
                );
            },
            error => {
                this.handleError(error);
            }
        );
    }

    /** overwritten to fix outcome missing issue */
    saveTaskFormOverwritten(outcome: FormOutcomeModel) {
        if (this.form && this.form.taskId) {
            this.formService.saveTaskForm(this.form.taskId, this.form.values).subscribe(
                () => {
                    this.onTaskSavedOverwritten(this.form, outcome.name);
                    this.storeFormAsMetadata();
                },
                error => this.onTaskSavedError(this.form, error)
            );
        }
    }

    /** overwritten to fix the conflict between outcome and required fields issue */
    isOutcomeButtonEnabled(outcome: FormOutcomeModel): boolean {
        if (this.form.readOnly) {
            return false;
        }

        if (outcome) {
            if (outcome.name.toLocaleLowerCase().includes(this.formOutcomeBypass)) {
                return true;
            }
            if (outcome.name === FormOutcomeModel.SAVE_ACTION) {
                return !this.disableSaveButton;
            }
            if (outcome.name === FormOutcomeModel.COMPLETE_ACTION) {
                return this.disableCompleteButton ? false : this.form.isValid;
            }
            if (outcome.name === FormOutcomeModel.START_PROCESS_ACTION) {
                return this.disableStartProcessButton ? false : this.form.isValid;
            }
            return this.form.isValid;
        }
        return false;
    }

    completeTaskForm(outcome?: string) {
        if (this.form && this.form.taskId) {
            /** overwritten to fix the conflict between outcome and required fields issue */
            if (outcome?.toLocaleLowerCase().includes(this.formOutcomeBypass)) {
                this.form?.fieldsCache?.forEach(formField => {
                    if (formField.required) {
                        const key = formField.id;

                        if (this.form.values[key] !== undefined) {
                            if (this.form.values[key] === null) {
                                //set field to blank based on field type and expected value type
                                if (formField.type == "functional-group") {
                                    this.form.values[key] = this.formOutcomeBypassGroup;
                                } else {
                                    this.form.values[key] = "";
                                }
                            }
                        } else {
                            this.form.values[key] = "";
                        }
                    }
                });
            }

            this.formService.completeTaskForm(this.form.taskId, this.form.values, outcome).subscribe(
                () => {
                    this.onTaskCompleted(this.form, outcome);
                    this.storeFormAsMetadata();
                },
                error => this.onTaskCompletedError(this.form, error)
            );
        }
    }

    handleError(err: any): any {
        this.error.emit(err);
    }

    parseForm(formRepresentationJSON: any): FormModel {
        if (formRepresentationJSON) {
            const form = new FormModel(formRepresentationJSON, this.data, this.readOnly, this.formService, this.enableFixedSpacedForm);
            if (!formRepresentationJSON.fields) {
                form.outcomes = this.getFormDefinitionOutcomes(form);
            }

            if (this.fieldValidators && this.fieldValidators.length > 0) {
                form.fieldValidators = this.fieldValidators;
            }
            return form;
        }
        return null;
    }

    /**
     * Get custom set of outcomes for a Form Definition.
     *
     * @param form Form definition model.
     */
    getFormDefinitionOutcomes(form: FormModel): FormOutcomeModel[] {
        return [new FormOutcomeModel(form, {id: "$save", name: FormOutcomeModel.SAVE_ACTION, isSystem: true})];
    }

    checkVisibility(field: FormFieldModel) {
        if (field && field.form) {
            this.visibilityService.refreshVisibility(field.form);
        }
    }

    loadFormFromActiviti(nodeType: string): any {
        this.formService.searchFrom(nodeType).subscribe(
            form => {
                if (!form) {
                    this.formService.createFormFromANode(nodeType).subscribe(formMetadata => {
                        this.loadFormFromFormId(formMetadata.id);
                    });
                } else {
                    this.loadFormFromFormId(form.id);
                }
            },
            error => {
                this.handleError(error);
            }
        );
    }

    /**
     * Invoked when user clicks outcome button.
     *
     * Overwrite parent method
     *
     * @param outcome Form outcome model
     */
    onOutcomeClicked(outcome: FormOutcomeModel): boolean {
        if (!this.readOnly && outcome && this.form) {
            if (!this.onExecuteOutcome(outcome)) {
                return false;
            }

            if (outcome.isSystem) {
                if (outcome.id === FormBaseComponent.SAVE_OUTCOME_ID) {
                    this.saveTaskFormOverwritten(outcome);
                    return true;
                }

                if (outcome.id === FormBaseComponent.COMPLETE_OUTCOME_ID) {
                    this.completeTaskForm();
                    return true;
                }

                if (outcome.id === FormBaseComponent.START_PROCESS_OUTCOME_ID) {
                    this.completeTaskForm();
                    return true;
                }

                if (outcome.id === FormBaseComponent.CUSTOM_OUTCOME_ID) {
                    this.onTaskSavedOverwritten(this.form, outcome.name);
                    this.storeFormAsMetadata();
                    return true;
                }
            } else {
                // Note: Activiti is using NAME field rather than ID for outcomes
                if (outcome.name) {
                    this.onTaskSavedOverwritten(this.form, outcome.name);
                    this.completeTaskForm(outcome.name);
                    return true;
                }
            }
        }

        return false;
    }

    protected storeFormAsMetadata() {
        if (this.saveMetadata) {
            this.ecmModelService.createEcmTypeForActivitiForm(this.formName, this.form).subscribe(
                type => {
                    this.nodeService.createNodeMetadata(type.nodeType || type.entry.prefixedName, EcmModelService.MODEL_NAMESPACE, this.form.values, this.path, this.nameNode);
                },
                error => {
                    this.handleError(error);
                }
            );
        }
    }

    protected onFormLoaded(form: FormModel) {
        this.formLoaded.emit(form);
        this.formService.formLoaded.next(new FormEvent(form));
    }

    protected onFormDataRefreshed(form: FormModel) {
        this.formDataRefreshed.emit(form);
        this.formService.formDataRefreshed.next(new FormEvent(form));
    }

    protected onTaskSavedOverwritten(form: FormModel, type: string) {
        this.formSaved.emit({form: form, type: type});
        this.formService.taskSaved.next(new FormEvent(form));
    }

    protected onTaskSaved(form: FormModel) {
        console.log("Wrong method called, this is only a placeholder for abstract class");
    }

    saveTaskForm() {
        console.log("Wrong method called, this is only a placeholder for abstract class");
    }

    protected onTaskSavedError(form: FormModel, error: any) {
        this.handleError(error);
        this.formService.taskSavedError.next(new FormErrorEvent(form, error));
    }

    protected onTaskCompleted(form: FormModel, type: string) {
        this.formCompleted.emit({form: form, type: type});
        this.formService.taskCompleted.next(new FormEvent(form));
    }

    protected onTaskCompletedError(form: FormModel, error: any) {
        this.handleError(error);
        this.formService.taskCompletedError.next(new FormErrorEvent(form, error));
    }

    protected onExecuteOutcome(outcome: FormOutcomeModel): boolean {
        const args = new FormOutcomeEvent(outcome);

        this.formService.executeOutcome.next(args);
        if (args.defaultPrevented) {
            return false;
        }

        this.executeOutcome.emit(args);
        return !args.defaultPrevented;
    }

    private refreshFormData() {
        this.form = this.parseForm(this.form.json);
        this.onFormLoaded(this.form);
        this.onFormDataRefreshed(this.form);
    }

    private loadFormForEcmNode(nodeId: string): void {
        this.nodeService.getNodeMetadata(nodeId).subscribe(data => {
            this.data = data.metadata;
            this.loadFormFromActiviti(data.nodeType);
        }, this.handleError);
    }

    private loadFormFromFormId(formId: number) {
        this.formId = formId;
        this.loadForm();
    }
}
