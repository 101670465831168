<div class="task">
    <mat-tab-group (selectedTabChange)="onTabChange($event)" [selectedIndex]="selectedTabIndex">
        <div *ngFor="let view of taskViews">
            <mat-tab id="view.value" label="{{ view.name }}" [disabled]="isLoadingResults">
                <ng-template matTabContent>
                    <adf-toolbar class="tab-filter-sort">
                        <mat-form-field appearance="fill" class="filter-form-field" floatLabel="auto">
                            <mat-label>Partial Filter</mat-label>
                            <input matInput placeholder="Fuzzy search based on the search result" (keyup)="applyFilter($event)" #input />
                        </mat-form-field>

                        <adf-toolbar-divider></adf-toolbar-divider>
                        <mat-form-field appearance="fill">
                            <mat-label>Ordered By {{ sortName() }}</mat-label>
                            <mat-select [disabled]="isLoadingResults" (selectionChange)="onSortSelectionChange($event)" [(value)]="sortedAllBy">
                                <mat-option value="created-desc">Created Desc</mat-option>
                                <mat-option value="created-asc">Created Asc</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <adf-toolbar-divider></adf-toolbar-divider>

                        <mat-form-field *ngIf="showProgressFilter" appearance="fill">
                            <mat-label>Filter By Process Status</mat-label>
                            <mat-select [disabled]="isLoadingResults" (selectionChange)="onFilterProcessStatusChange($event)" [(value)]="filterProcessAllBy">
                                <mat-option value="running">In Progress</mat-option>
                                <mat-option value="completed">Completed</mat-option>
                                <mat-option value="all">All</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field *ngIf="!showProgressFilter" appearance="fill">
                            <mat-label>Filter By Task Status</mat-label>
                            <mat-select [disabled]="isLoadingResults" (selectionChange)="onFilterTaskStatusChange($event)" [(value)]="filterTaskAllBy">
                                <mat-option value="active">In Progress</mat-option>
                                <mat-option value="completed">Completed</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <adf-toolbar-divider *ngIf="showProgressFilter"></adf-toolbar-divider>
                        <mat-form-field *ngIf="showProgressFilter" appearance="fill">
                            <mat-label>Created Date</mat-label>
                            <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                                <input matStartDate formControlName="start" placeholder="Start date" [value]="range.start" />
                                <input matEndDate formControlName="end" placeholder="End date" />
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker></mat-date-range-picker>
                            <mat-datepicker-toggle matSuffix (click)="clearDate()">
                                <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                            </mat-datepicker-toggle>
                        </mat-form-field>

                        <adf-toolbar-divider *ngIf="!showProgressFilter"></adf-toolbar-divider>
                        <mat-form-field *ngIf="!showProgressFilter" appearance="fill">
                            <mat-label>Filter By Name</mat-label>
                            <input matInput (input)="onFilterByNameChange($event.target.value)" [value]="nameFilter" />
                            <mat-datepicker-toggle matSuffix (click)="clearTaskNameFilter()">
                                <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                            </mat-datepicker-toggle>
                        </mat-form-field>

                        <adf-toolbar-divider></adf-toolbar-divider>
                        <button mat-icon-button matSuffix matTooltip="Apply filters" (click)="onFilterClick()">
                            <mat-icon>{{ "restart_alt" }}</mat-icon>
                        </button>

                        <adf-toolbar-divider *ngIf="showProgressFilter"></adf-toolbar-divider>
                        <button *ngIf="showProgressFilter" mat-icon-button matSuffix matTooltip="Clear table filters" (click)="onClearFilterClick()">
                            <mat-icon>{{ "cancel" }}</mat-icon>
                        </button>
                    </adf-toolbar>
                </ng-template>
            </mat-tab>
        </div>
        <mat-tab labelClass="action-bar" disabled>
            <ng-template mat-tab-label>
                <button class="action-bar-button" *ngIf="showClaimButton()" mat-icon-button matTooltip="Claim all selected tasks" [disabled]="isClaimClicked || this.selection.selected.length === 0" (click)="onClaimAll()">
                    <mat-icon>done_all</mat-icon>
                </button>

                <button class="action-bar-button" mat-icon-button matTooltip="Download CSV" (click)="onDownloadCSVFile()">
                    <mat-icon>file_download</mat-icon>
                </button>

                <button class="action-bar-button" *ngIf="isGetDataFromTask()" mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon>menu</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item>
                        <mat-slide-toggle [(ngModel)]="showTaskInfo" [disabled]="!isProcess()">
                            {{ settingToggleName() }}
                        </mat-slide-toggle>
                    </button>
                </mat-menu>
            </ng-template>
        </mat-tab>
    </mat-tab-group>

    <div class="table-container">
        <ng-container *ngIf="this.showProcessInfo(); else taskElse">
            <table mat-table matTableExporter [dataSource]="dataSource" cdkDropList cdkDropListLockAxis="x" cdkDropListOrientation="horizontal" (cdkDropListDropped)="dropProcessListDropped($event)">
                <ng-container matColumnDef="select" sticky>
                    <th mat-header-cell *matHeaderCellDef cdkDrag>
                        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()"> </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"> </mat-checkbox>
                    </td>
                </ng-container>
                <ng-container matColumnDef="index">
                    <th mat-header-cell *matHeaderCellDef cdkDrag></th>
                    <td mat-cell *matCellDef="let row; let i = index">
                        {{ this.paginator.pageIndex == 0 ? i + 1 : 1 + i + this.paginator.pageIndex * this.paginator.pageSize }}
                    </td>
                </ng-container>
                <ng-container *ngFor="let col of processSchema" matColumnDef="{{ col.key }}">
                    <th mat-header-cell *matHeaderCellDef matTooltip="{{ col.formate }}" cdkDrag>
                        <ng-container *ngIf="col.filterable && isProcess(); else elseBlock">
                            <mat-form-field class="column-filter" appearance="standard">
                                <mat-label>{{ col.title }}</mat-label>
                                <input id="{{ col.key }}" matInput (input)="onProcessFilterChange($event)" [value]="processTableFilters.get(col.key) ? processTableFilters.get(col.key) : ''" />
                                <button *ngIf="processTableFilters.get(col.key)" matSuffix mat-icon-button aria-label="Clear" (click)="processTableFilters.delete(col.key, '')">
                                    <mat-icon>close</mat-icon>
                                </button>
                            </mat-form-field>
                        </ng-container>
                        <ng-template #elseBlock>{{ col.title }}</ng-template>
                    </th>
                    <div *ngIf="col.asHyperlink; else elseBlock">
                        <ng-container *ngIf="col.key === 'eSignStatusName'; else elseBlock1">
                            <td mat-cell *matCellDef="let element">
                                <a href="{{ col.url }}{{ element['processId'] }}" target="_blank">{{ element[col.key] }}</a>
                            </td>
                        </ng-container>
                        <ng-template #elseBlock1>
                            <td mat-cell *matCellDef="let element">
                                <a href="{{ col.url }}{{ element[col.key] }}" target="_blank">{{ element[col.key] }}</a>
                            </td>
                        </ng-template>
                    </div>
                    <ng-template #elseBlock>
                        <td mat-cell *matCellDef="let element">
                            <ng-container *ngIf="col.displayAsDoc; else elseBlock">
                                <ng-container *ngIf="showDownloadLink(col, element)">
                                    <a href="{{ col.url }}{{ element[col.key] }}" target="_blank">Download</a>
                                </ng-container>
                            </ng-container>
                            <ng-template #elseBlock>
                                <div class="data-style" [innerHTML]="element[col.key]"></div>
                            </ng-template>
                        </td>
                    </ng-template>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="processDisplayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: processDisplayedColumns"></tr>
                <ng-container *ngIf="!isLoadingResults || isClaimClicked">
                    <tr class="mat-row" *matNoDataRow style="text-align: center">
                        <td class="mat-cell" colspan="9999">{{ message() }}</td>
                    </tr>
                </ng-container>
            </table>
        </ng-container>
        <ng-template #taskElse>
            <table mat-table matTableExporter [dataSource]="dataSource" matSort cdkDropList cdkDropListLockAxis="x" cdkDropListOrientation="horizontal" (cdkDropListDropped)="dropTaskListDropped($event)">
                <ng-container matColumnDef="select" sticky>
                    <th mat-header-cell *matHeaderCellDef cdkDrag>
                        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()"> </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"> </mat-checkbox>
                    </td>
                </ng-container>
                <ng-container matColumnDef="index">
                    <th mat-header-cell *matHeaderCellDef cdkDrag></th>
                    <td mat-cell *matCellDef="let row; let i = index">
                        {{ this.paginator.pageIndex == 0 ? i + 1 : 1 + i + this.paginator.pageIndex * this.paginator.pageSize }}
                    </td>
                </ng-container>
                <ng-container *ngFor="let col of taskSchema; let i = index" matColumnDef="{{ col.key }}">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="{{ col.formate }}" cdkDrag>
                        {{ col.title }}
                    </th>
                    <div *ngIf="col.asHyperlink; else elseBlock">
                        <td mat-cell *matCellDef="let element">
                            <a href="{{ col.url }}{{ element[col.key] }}" target="_blank">{{ element[col.key] }}</a>
                        </td>
                    </div>
                    <ng-template #elseBlock>
                        <td mat-cell *matCellDef="let element">
                            {{ element[col.key] }}
                        </td>
                    </ng-template>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="taskDisplayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: taskDisplayedColumns"></tr>
                <ng-container *ngIf="!isLoadingResults || isClaimClicked">
                    <tr class="mat-row" *matNoDataRow style="text-align: center">
                        <td class="mat-cell" colspan="9999">{{ message() }}</td>
                    </tr>
                </ng-container>
            </table>
        </ng-template>
        <div *ngIf="isLoadingResults || isClaimClicked" class="spinner-continer">
            <mat-progress-spinner color="primary" mode="indeterminate"> </mat-progress-spinner>
        </div>
    </div>
    <mat-paginator [pageSizeOptions]="getPageSizeOptions()" (page)="onPageChange($event)"></mat-paginator>
    <!-- <adf-viewer
        [overlayMode]="true"
        [overlayMode]="true"
        [nodeId]= "'e9f25776-cc17-4547-af6a-e909dfd5d899'">
    </adf-viewer> -->
</div>
