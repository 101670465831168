import {AlfrescoApiService, AuthenticationService, BpmUserModel, BpmUserService, LogService} from "@alfresco/adf-core";
import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {from, Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {
    AlfrescoApi,
    ProcessInstancesApi,
    HistoricProcessInstanceQueryRepresentation,
    TaskQueryRepresentation,
    TasksApi,
    TaskRepresentation,
    ProcessInstanceVariableRepresentation,
    ProcessInstanceRepresentation,
    HistoricTaskInstanceQueryRepresentation,
    ResultListDataRepresentationTaskRepresentation
} from "@alfresco/js-api";
import {TaskListModel, TaskQueryRequestRepresentationModel, TaskListService, TaskDetailsModel} from "@alfresco/adf-process-services";

@Injectable({
    providedIn: "root"
})
export class AppStateService {
    private _processInstancesApi: ProcessInstancesApi;
    get processInstancesApi(): ProcessInstancesApi {
        this._processInstancesApi = this._processInstancesApi ?? new ProcessInstancesApi(this.apiService.getInstance());
        return this._processInstancesApi;
    }

    private _tasksApi: TasksApi;
    get tasksApi(): TasksApi {
        this._tasksApi = this._tasksApi ?? new TasksApi(this.apiService.getInstance());
        return this._tasksApi;
    }

    constructor(private router: Router, private authService: AuthenticationService, private apiService: AlfrescoApiService, private logService: LogService, private bpmUserService: BpmUserService, private taskListService: TaskListService) {}

    public getLastTaskFormData(lastTaskId: string): Promise<any> {
        return this.processAPIClient("/api/enterprise/task-forms/" + lastTaskId, "GET", {});
    }

    public getProcessInstanceVariables(processDefinitionKey: string, size: number): Promise<any> {
        return this.processAPIClient("/api/enterprise/historic-process-instances/query", "POST", {processDefinitionKey: processDefinitionKey, size: size, includeProcessVariables: true});
    }

    public getProcessInstances(request: HistoricProcessInstanceQueryRepresentation, customAPIPoint: boolean) {
        if (customAPIPoint) {
            return this.processAPIClient("/api/enterprise/custom/ua/aps/process", "POST", request);
        } else {
            return this.processInstancesApi.getHistoricProcessInstances(request);
        }
    }

    public getProcessInstanceById(ids: {process: string; task: string}): Promise<{process: ProcessInstanceRepresentation; ids: {process: string; task: string}}> {
        return new Promise((resolve, reject) => {
            this.processInstancesApi
                .getProcessInstance(ids.process)
                .then(processInstanceRepresentation => {
                    return resolve({process: processInstanceRepresentation, ids: ids});
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    // result will according user role
    public getHistoricTasks(queryRequest: HistoricTaskInstanceQueryRepresentation): Promise<ResultListDataRepresentationTaskRepresentation> {
        return this.tasksApi.listHistoricTasks(queryRequest);
    }

    public getTasks(requestNode: TaskQueryRequestRepresentationModel): Observable<TaskListModel> {
        return from(this.callApiTasksFiltered(requestNode)).pipe(catchError(err => this.handleError(err)));
    }

    public getTask(taskId: string): Promise<TaskRepresentation> {
        return this.tasksApi.getTask(taskId);
    }

    public getTaskInstanceVariables(lastTaskId: string): Promise<any> {
        return this.processAPIClient("/api/enterprise/task-forms/" + lastTaskId + "/variables", "GET", {});
    }

    public getUserInfo(): Observable<BpmUserModel> {
        return this.bpmUserService.getCurrentUserInfo();
    }

    public claimTask(taskId: string): Observable<TaskDetailsModel> {
        return this.taskListService.claimTask(taskId);
    }

    logout(oauthType: String) {
        this.authService.logout().subscribe(() => {
            if (oauthType.toUpperCase() === "BASIC") {
                this.router.navigate(["/login"]);
            }
        });
    }

    public callECMApi(path: string, httpMethod: string): Promise<any> {
        return this.apiService.getInstance().contentClient.callApi(path, httpMethod, {}, {}, {}, {}, {}, ["application/json"], ["application/json"]);
    }

    private processAPIClient(path: string, httpMethod: string, bodyParam: any): Promise<any> {
        const instance: AlfrescoApi = this.apiService.getInstance();
        return instance.processClient.callApi(path, httpMethod, {}, {}, {}, {}, bodyParam, ["application/json"], ["application/json"]);
    }

    private callApiTasksFiltered(requestNode: TaskQueryRepresentation): Promise<TaskListModel> {
        return this.tasksApi.listTasks(requestNode);
    }

    private handleError(error: any) {
        this.logService.error(error);
        return throwError(error || "Server error");
    }
}
