import {Routes} from "@angular/router";
import {AuthGuardBpm, AuthGuardEcm, AuthGuard} from "@alfresco/adf-core";
import {LoginComponent} from "./login/login.component";
import {AppsComponent} from "./apps/apps.component";
import {TasksComponent} from "./tasks/tasks.component";
import {UofATaskFormComponent} from "./task-form/task-form.component";
import {AppLayoutComponent} from "./app-layout/app-layout.component";
import {PageStatusComponent} from "./page-status/page-status.component";
import {AuthGuardMaintenance} from "./services/auth-guard.service";
import {UofaStartProcessComponent} from "./start-process/start-process.component";

export const appRoutes: Routes = [
    {
        path: "",
        component: AppLayoutComponent,
        canActivate: [AuthGuardMaintenance],
        children: [
            {
                path: "",
                component: AppsComponent,
                canActivate: [AuthGuardBpm, AuthGuardEcm]
            },
            {
                path: "apps/:appId",
                component: TasksComponent,
                canActivate: [AuthGuardBpm, AuthGuardEcm]
            },
            {
                path: "apps/:appId/task/:taskId",
                component: UofATaskFormComponent,
                canActivate: [AuthGuardBpm, AuthGuardEcm]
            },
            {
                path: "startProcess",
                component: UofaStartProcessComponent,
                canActivate: [AuthGuardBpm, AuthGuardEcm]
            }
        ]
    },
    {
        path: "login",
        component: LoginComponent,
        canActivate: [AuthGuardMaintenance]
    },
    {
        path: "status",
        component: AppLayoutComponent,
        children: [
            {
                path: "",
                component: PageStatusComponent
            }
        ]
    },
    {path: "**", redirectTo: "/status"}
];
