/* Todo add rich editor */

import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import {WidgetComponent, FormService, FormFieldValidator, FormFieldModel} from "@alfresco/adf-core";

@Component({
    selector: "display-rich-text",
    templateUrl: "./display-rich-text.widget.html",
    styleUrls: ["./display-rich-text.widget.scss"],
    host: {
        "(click)": "event($event)",
        "(blur)": "event($event)",
        "(change)": "event($event)",
        "(focus)": "event($event)",
        "(focusin)": "event($event)",
        "(focusout)": "event($event)",
        "(input)": "event($event)",
        "(invalid)": "event($event)",
        "(select)": "event($event)"
    },
    encapsulation: ViewEncapsulation.Emulated
})
export class DisplayRichTextWidgetComponent extends WidgetComponent implements OnInit {
    constructor(public formService: FormService) {
        super(formService);
    }

    ngOnInit(): void {
        // this.field.form.fieldValidators.push(this.fieldValidator);

        if (this.field?.params?.customProperties) {
            this.readOnly = this.field.params.customProperties["uofa-textarea-readonly"] == "true" ? true : false;
        }
    }
}

export class DisplayRichTextValidator implements FormFieldValidator {
    isSupported(field: FormFieldModel): boolean {
        return field && field.type === "textarea";
    }

    validate(field: FormFieldModel): boolean {
        if (this.isSupported(field) && field.isVisible) {
            if (field.required && (field.value === null || field.value === undefined || field.value === "")) {
                return false;
            }
        }
        return true;
    }
}
