<adf-login copyrightText="© 2024 University of Alberta" [logoImageUrl]="logoImageUrl" [backgroundImageUrl]="backgroundImageUrl" [showRememberMe]="false" [successRoute]="successRoute">
    <adf-login-header>
        <p>
            * You must be on the University VPN in order to use Alfresco Services. Instructions to download and install the University VPN software can be found
            <a target="_blank" href="https://universityofalberta.freshservice.com/support/solutions/articles/19000109142">here</a>
        </p>
        <p>* Please login with your CCID and Password.</p>
    </adf-login-header>
    <adf-login-footer>
        <ng-template>
            <p *ngIf="showFooterMessage">
                * You must be on the <strong>University VPN</strong> in order to use Alfresco Services. Instructions to download and install the University VPN software can be found
                <a target="_blank" href="https://universityofalberta.freshservice.com/support/solutions/articles/19000109142">here</a>
            </p>
        </ng-template>
    </adf-login-footer>
</adf-login>
