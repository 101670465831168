import {ChangeDetectionStrategy, Component} from "@angular/core";
import {MatSelectModule} from "@angular/material/select";
import {MatInputModule} from "@angular/material/input";
import {MatFormFieldModule} from "@angular/material/form-field";

@Component({
    selector: "facultylist-widget",
    templateUrl: "./facultylist.component.html",
    styleUrls: ["./facultylist.component.scss"]
})
export class FacultyListWidgetComponent {}
