import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {AppDefinitionRepresentationModel} from "@alfresco/adf-process-services";
import {AppConfigService} from "@alfresco/adf-core";

@Component({
    selector: "app-apps",
    templateUrl: "./apps.component.html",
    styleUrls: ["./apps.component.scss"]
})
export class AppsComponent implements OnInit {
    constructor(private router: Router, private appConfigService: AppConfigService) {}

    public filtersAppId = [];

    onAppClicked(app: AppDefinitionRepresentationModel) {
        this.router.navigate(["/apps", app.id || 0]);
    }

    ngOnInit() {
        this.filtersAppId = this.appConfigService.config.apps.appFilters;
    }
}
