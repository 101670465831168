import {FormRenderingService, FormValues, NotificationService} from "@alfresco/adf-core";
import {ProcessInstance, ProcessDefinitionRepresentation} from "@alfresco/adf-process-services";
import {Component, OnInit, ViewEncapsulation, Output, EventEmitter} from "@angular/core";
import {UntypedFormControl, Validators, AbstractControl} from "@angular/forms";
import {Router} from "@angular/router";
import {CCIDWidgetComponent} from "app/widgets/ccid/ccidLookUp.component";
import {DisplayRichTextWidgetComponent} from "app/widgets/display-rich-text/display-rich-text.widget";
import {DisplayHTMLWidgetComponent} from "app/widgets/display-html/display-html.component";
import {SiteListWidgetComponent} from "app/widgets/sitelist/sitelist.component";
import {CCIDListWidgetComponent} from "app/widgets/ccidlist/ccidList.component";

const MAX_LENGTH = 255;
@Component({
    selector: "uofa-start-process",
    templateUrl: "./start-process.component.html",
    styleUrls: ["./start-process.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class UofaStartProcessComponent implements OnInit {
    movedNodeToPS: FormValues;
    processNameInput: UntypedFormControl;

    selectedProcessDef: ProcessDefinitionRepresentation;
    /** Emitted when the process is canceled. */
    @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
    maxProcessNameLength: number = MAX_LENGTH;

    constructor(private formRenderingService: FormRenderingService, private notificationService: NotificationService, private router: Router) {
        this.formRenderingService.setComponentTypeResolver("ccid_look_up", () => CCIDWidgetComponent, true);
        this.formRenderingService.setComponentTypeResolver("textarea", () => DisplayRichTextWidgetComponent, true);
        this.formRenderingService.setComponentTypeResolver("display_html", () => DisplayHTMLWidgetComponent, true);
        this.formRenderingService.setComponentTypeResolver("sitelist", () => SiteListWidgetComponent, true);
        this.formRenderingService.setComponentTypeResolver("ccidlist", () => CCIDListWidgetComponent, true);
    }

    ngOnInit(): void {
        this.processNameInput = new UntypedFormControl("", [Validators.required, Validators.maxLength(this.maxProcessNameLength), Validators.pattern("^[^\\s]+(\\s+[^\\s]+)*$")]);
    }

    reload() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = "reload";
        this.router.navigate(["./startProcess"]);
    }

    onError(event) {
        this.notificationService.showError(event.error?.message);
    }

    onStart(process: ProcessInstance) {
        this.reload();
        this.notificationService.showInfo("Workflow Started", "Reload", 1000);
    }

    get nameController(): AbstractControl {
        return this.processNameInput;
    }

    startProcess(outcome?: string) {}

    cancelStartProcess(): void {
        this.cancel.emit();
    }

    onCancel() {
        this.notificationService
            .showWarning("Workflow Canceled", "Reload", 10000)
            .afterDismissed()
            .subscribe(() => {
                this.reload();
            });
    }
}
