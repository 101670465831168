import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import {FormService, WidgetComponent} from "@alfresco/adf-core";

@Component({
    selector: "uofa-document",
    templateUrl: "./uofa-document.component.html",
    host: {
        "(click)": "event($event)",
        "(blur)": "event($event)",
        "(change)": "event($event)",
        "(focus)": "event($event)",
        "(focusin)": "event($event)",
        "(focusout)": "event($event)",
        "(input)": "event($event)",
        "(invalid)": "event($event)",
        "(select)": "event($event)"
    },
    encapsulation: ViewEncapsulation.Emulated
})
export class UofaDocumentWidgetComponent extends WidgetComponent implements OnInit {
    fileId: string = null;
    hasFile: boolean = false;
    message: string = "";
    docId: string = "";

    constructor(public formService: FormService) {
        super(formService);
    }

    ngOnInit() {
        const file = this.field?.params?.customProperties["uofa-doc-viewer"];
        if (file) {
            if (file.value) {
                this.fileId = file.value;
                this.hasFile = true;
            } else {
                this.fileId = null;
                this.hasFile = false;

                if (this.field?.form?.selectedOutcome?.includes("sign")) {
                    this.message = `You have just successfully completed the signature of the ${this.field.name}.`;
                }
            }
        } else {
            this.fileId = null;
            this.hasFile = false;
        }
    }
}
