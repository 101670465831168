<div class="uofa-multiline-text-widget {{field.className}}" [class.adf-invalid]="!field.isValid && isTouched()" [class.adf-readonly]="field.readOnly">
    <mat-form-field appearance="outline" [hideRequiredMarker]="true">
        <mat-label class="adf-label" [attr.for]="field.id" [style.color]=' "Black" '> {{field.name | translate }}<span class="adf-asterisk" *ngIf="isRequired()">*</span> </mat-label>
        <textarea
            matInput
            class="adf-input"
            [cdkTextareaAutosize]="true"
            type="text"
            rows="3"
            [id]="field.id"
            [required]="isRequired()"
            [(ngModel)]="field.value"
            (ngModelChange)="onFieldChanged(field)"
            [disabled]="field.readOnly || readOnly"
            [placeholder]="field.placeholder"
            [matTooltip]="field.tooltip"
            (blur)="markAsTouched()"
            matTooltipPosition="above"
            matTooltipShowDelay="1000"
        >
        </textarea>
    </mat-form-field>
    <div *ngIf="field.maxLength > 0" class="uofa-multiline-word-counter">
        <span>{{field?.value?.length || 0}}/{{field.maxLength}}</span>
    </div>
    <error-widget [error]="field.validationSummary"></error-widget>
    <error-widget class="uofa-multiline-required-message" *ngIf="isInvalidFieldRequired() && isTouched()" required="{{ 'FORM.FIELD.REQUIRED' | translate }}"> </error-widget>
</div>
