import {FormService, WidgetComponent} from "@alfresco/adf-core";
import {Component, Input, OnChanges, Output, EventEmitter} from "@angular/core";
import {AppStateService} from "app/services/state.service";
import {MatSelectChange} from "@angular/material/select";

@Component({
    selector: "sitegroup-list",
    templateUrl: "./sitegrouplist.component.html",
    styleUrls: ["./sitegrouplist.component.scss"]
})
export class SiteGroupListWidgetComponent extends WidgetComponent implements OnChanges {
    @Input() selectedSite: string;
    siteGroups: string[] = [];
    myObject = {};
    selectedGroup: String;
    @Output() newSelectedGroupEvent = new EventEmitter<string>();

    constructor(private appStateService: AppStateService, public formService: FormService) {
        super(formService);
        this.selectedGroup = "Please select a value";
    }

    ngOnChanges() {
        this.siteGroups = [];
        if (this.selectedSite) {
            this.loadSiteGroup();
        }
    }

    loadSiteGroup() {
        this.appStateService.callECMApi("/groups/GROUP_" + this.selectedSite + "/members?maxItems=200&where=(memberType%3D'GROUP')", "GET").then(
            v => {
                this.myObject = v.list.entries;
                for (var i = 0; i < v.list.pagination.count; i++) {
                    if (this.myObject[i].entry.id.toLowerCase().indexOf("approver") < 0) {
                        this.siteGroups.push(this.myObject[i].entry.id);
                    }
                }
            },
            e => {
                console.log("Error from siteGroup" + e);
            }
        );
    }

    getSelected(event: MatSelectChange) {
        this.newSelectedGroupEvent.emit(event.value);
    }
}
