import {AppConfigService} from "@alfresco/adf-core";
import {Component, OnInit} from "@angular/core";

@Component({
    selector: "app-page-status",
    templateUrl: "./page-status.component.html",
    styleUrls: ["./page-status.component.scss"]
})
export class PageStatusComponent implements OnInit {
    maintenance: {status: boolean; message: string};
    logo = "assets/images/Primary_Logo_Complete_Package/White/Digital/UA_Logo_WHT_RGB.png";
    backgroundColor = "#275d38";

    constructor(private appConfigService: AppConfigService) {}

    ngOnInit(): void {
        this.maintenance = this.appConfigService.config.apps.maintenance;
    }
}
