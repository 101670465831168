import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation} from "@angular/core";
import {ContentLinkModel, DownloadService, FormService, ProcessContentService} from "@alfresco/adf-core";
import {Observable} from "rxjs";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";

@Component({
    selector: "uofa-adf-content",
    templateUrl: "./uofa-content.component.html",
    styleUrls: ["./uofa-content.component.scss"],
    encapsulation: ViewEncapsulation.Emulated
})
// ContentLinkModel
export class UofaContentWidgetComponent implements OnChanges {
    /** The content id to show. */
    @Input()
    id: string;

    /** Toggles showing document content. */
    @Input()
    showDocumentContent: boolean = true;

    /** Emitted when the content is clicked. */
    @Output()
    contentClick = new EventEmitter();

    /** Emitted when the thumbnail has loaded. */
    @Output()
    thumbnailLoaded: EventEmitter<any> = new EventEmitter<any>();

    /** Emitted when the content has loaded. */
    @Output()
    contentLoaded: EventEmitter<any> = new EventEmitter<any>();

    /** Emitted when an error occurs. */
    @Output()
    error: EventEmitter<any> = new EventEmitter<any>();

    content: ContentLinkModel;

    blobURL: SafeResourceUrl;

    constructor(protected formService: FormService, private downloadService: DownloadService, private processContentService: ProcessContentService, private sanitizer: DomSanitizer) {
        this.blobURL = this.sanitizer.bypassSecurityTrustResourceUrl("");
    }

    ngOnChanges(changes: SimpleChanges) {
        const contentId = changes["id"];
        if (contentId?.currentValue) {
            this.loadContent(contentId.currentValue);
        }
    }

    loadContent(id: number) {
        this.processContentService.getFileContent(id).subscribe(
            (response: ContentLinkModel) => {
                this.content = new ContentLinkModel(response);
                this.contentLoaded.emit(this.content);
                /** disable loadThumbnailUrl as we created our own document widget*/
                //this.loadThumbnailUrl(this.content);

                this.loadFileViewer(this.content);
            },
            error => {
                this.error.emit(error);
            }
        );
    }

    loadThumbnailUrl(content: ContentLinkModel) {
        if (this.content.isThumbnailSupported()) {
            let observable: Observable<any>;

            if (this.content.isTypeImage()) {
                observable = this.processContentService.getFileRawContent(content.id);
            } else {
                observable = this.processContentService.getContentThumbnail(content.id);
            }

            if (observable) {
                observable.subscribe(
                    (response: Blob) => {
                        this.content.thumbnailUrl = this.createTrustedUrl(response) as string;
                        this.thumbnailLoaded.emit(this.content.thumbnailUrl);
                    },
                    error => {
                        this.error.emit(error);
                    }
                );
            }
        }
    }

    openViewer(content: ContentLinkModel): void {
        let fetch = this.processContentService.getContentPreview(content.id);
        if (content.isTypeImage() || content.isTypePdf()) {
            fetch = this.processContentService.getFileRawContent(content.id);
        }
        fetch.subscribe(
            (blob: Blob) => {
                content.contentBlob = blob;
                this.contentClick.emit(content);
                this.formService.formContentClicked.next(content);
            },
            error => {
                this.error.emit(error);
            }
        );
    }

    /** load file into UofA file viewer */
    loadFileViewer(content: ContentLinkModel): void {
        let fetch = this.processContentService.getContentPreview(content.id);
        if (content.isTypeImage() || content.isTypePdf()) {
            fetch = this.processContentService.getFileRawContent(content.id);
        }
        fetch.subscribe(
            (blob: Blob) => {
                content.contentBlob = blob;
                this.blobURL = this.createTrustedUrl(blob);
            },
            error => {
                this.error.emit(error);
            }
        );
    }

    /**
     * Invoke content download.
     *
     * @param content content link model
     */
    download(content: ContentLinkModel): void {
        this.processContentService.getFileRawContent(content.id).subscribe(
            (blob: Blob) => this.downloadService.downloadBlob(blob, content.name),
            error => {
                this.error.emit(error);
            }
        );
    }

    /**
     * Creates a trusted object URL from the Blob.
     * WARNING: calling this method with untrusted user data exposes your application to XSS security risks!
     *
     * @param  blob Data to wrap into object URL
     * @returns URL string SafeResourceUrl
     */
    createTrustedUrl(blob: Blob): SafeResourceUrl {
        const url = window.URL.createObjectURL(blob) + "#toolbar=1";
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}
